import styled from 'styled-components';

const Paper = styled.div`
  --color-gray-300: hsl(223deg, 5%, 60%);
  /* Accessible */
  --color-gray-500: hsl(223deg, 5%, 47%);
  --color-gray-700: hsl(223deg, 5%, 30%);
  --color-gray-900: hsl(223deg, 5%, 10%);
  --selection-background-color: hsl(0deg 0% 10% / 0.75);
  --selection-text-color: hsl(0deg, 0%, 100%);
  --color-link: hsl(250deg 75% 50%);
  color-scheme: light;

  position: relative;
  background: white;
  color: var(--color-gray-900);
  border-radius: 8px;
  padding: 32px;
  /* Optical alignment */
  padding-top: 26px;
  border-radius: 4px;
  /* prettier-ignore */
  box-shadow:
    0 0.9px 3px rgba(0, 0, 0, 0.042),
    0 2.2px 7.1px rgba(0, 0, 0, 0.061),
    0 4.1px 13.4px rgba(0, 0, 0, 0.075),
    0 7.4px 23.9px rgba(0, 0, 0, 0.089),
    0 13.8px 44.7px rgba(0, 0, 0, 0.108),
    0 33px 107px rgba(0, 0, 0, 0.15)
  ;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding: 24px;
    margin: 0 -16px;
    margin-top: 32px;
    border-radius: 0px;
  }
`;

export default Paper;
