import React from "react";
import styled from "styled-components";

export function TopCurve() {
  return (
    <Svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 440 52"
      style={{ "--transform": "translateY(2px) scale(1.1)" }}
    >
      <path d="M220 1C133.228-1.86 0 33 0 33v18.5h439.5V12s-24.077 14.898-41 21C331.878 57.02 290.781 3.333 220 1z"></path>
    </Svg>
  );
}

export function BottomCurve() {
  return (
    <Svg
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 441 73"
      style={{ "--transform": "translateY(-2px) scale(1.1)" }}
    >
      <path d="M133 39.5C80.795 39.858 0 26 0 26V0h440.5v51.5S427.546 62.233 418 67c-49.342 24.641-78.218-41.116-133-47.5-59.469-6.93-92.13 19.589-152 20z"></path>
    </Svg>
  );
}

const Svg = styled.svg`
  display: block;
  width: 100%;
  min-width: 700px;
  height: 80px;
  transform: var(--transform);

  path {
    fill: var(--color-background);
  }
`;
