export function getFormattedTimeRemaining(timeRemaining) {
  const distanceInSeconds = Math.floor(timeRemaining / 1000);
  const distanceInMinutes = Math.floor(distanceInSeconds / 60);
  const distanceInHours = Math.floor(distanceInMinutes / 60);
  const distanceInDays = Math.floor(distanceInHours / 24);

  let message;

  if (distanceInHours > 24) {
    message = `in ${distanceInDays} day${
      distanceInDays === 1 ? '' : 's'
    }.`;
  } else if (distanceInHours >= 1) {
    message = `in ${distanceInHours} hour${
      distanceInHours === 1 ? '' : 's'
    }.`;
  } else if (distanceInMinutes > 1) {
    message = `in ${distanceInMinutes} minutes!`;
  } else {
    message = `in ${distanceInSeconds} second${
      distanceInSeconds === 1 ? '' : 's'
    }!`;
  }

  return message;
}
