import React from 'react';
import { useSelector } from 'react-redux';

import {
  getSaleEnds,
  getTimeRemaining,
  getIsTwilightHour,
} from '@reducers/countdown.reducer';

function useIsOnSale({ delay } = { delay: 0 }) {
  const saleEnds = useSelector(getSaleEnds);
  const timeRemaining = useSelector(getTimeRemaining);
  const isTwilightHour = useSelector(getIsTwilightHour);

  const [isOnSale, setIsOnSale] = React.useState(false);

  React.useEffect(() => {
    const nextIsOnSale =
      typeof timeRemaining === 'number' && timeRemaining > 0;

    if (isOnSale === nextIsOnSale) {
      // No change
      return;
    }

    if (nextIsOnSale) {
      window.setTimeout(() => {
        setIsOnSale(true);
      }, delay);
    } else {
      setIsOnSale(false);
    }
  }, [isOnSale, timeRemaining]);

  return {
    isOnSale,
    saleEnds,
    timeRemaining,
    isTwilightHour,
  };
}

export default useIsOnSale;
