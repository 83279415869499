import React from 'react';
import styled from 'styled-components';

import UnstyledButton from '@components/UnstyledButton';

const BuyButton = ({ href, children, ...delegated }) => {
  const as = href ? 'a' : UnstyledButton;

  return (
    <Wrapper as={as} href={href} {...delegated}>
      <Shadow />
      <Backdrop />
      <Contents>{children}</Contents>
    </Wrapper>
  );
};

const Wrapper = styled.a`
  display: block;
  position: relative;
  text-decoration: none;
  transition: filter 600ms;

  &:hover {
    transition: filter 250ms;
    filter: brightness(110%);
    text-decoration: none;
  }

  &:disabled {
    filter: saturate(0%);
  }
`;

const Shadow = styled.span`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: hsl(0deg 0% 0% / 0.1);
  border-radius: 5px;
  /* Mouse-out styles */
  transform: translateY(4px);
  transition: all 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  will-change: transform;

  ${Wrapper}:not(:disabled):hover &,
  ${Wrapper}:not(:disabled).focus-visible & {
    transform: translateY(6px);
    transition: all 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }
  ${Wrapper}:not(:disabled):active & {
    transform: translateY(1px);
    transition: all 25ms;
  }
`;

const Backdrop = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to left,
    hsl(333deg 100% 22%) 0%,
    hsl(333deg 100% 32%) 3%,
    hsl(333deg 100% 32%) 97%,
    hsl(333deg 100% 22%) 100%
  );
  border-radius: 6px;
  box-shadow: 0px 1px 3px -1px hsl(333deg 100% 20% / 0.75);
`;
const Contents = styled.span`
  position: relative;
  height: 55px;
  border-radius: 6px;
  border: none;
  background: hsl(333deg 100% 45%);
  color: white;
  font-size: 21px;
  font-weight: 500;
  text-shadow: 0px 1px 1px var(--color-buy-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-4px);
  will-change: transform;
  transition: all 600ms cubic-bezier(0, 0.56, 0.53, 1.5);

  ${Wrapper}:not(:disabled):hover &,
  ${Wrapper}:not(:disabled).focus-visible & {
    transform: translateY(-8px);
    transition: all 250ms;
  }
  ${Wrapper}:not(:disabled):active & {
    transform: translateY(-2px);
    transition: all 25ms;
  }
`;

export default BuyButton;
