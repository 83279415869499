import React from 'react';
import styled, { keyframes } from 'styled-components';

const Question = ({ question, answer, slug }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const wrapperRef = React.useRef();

  React.useEffect(() => {
    const hashMatch = window.location.hash.match(/#faq-/i);

    // If theres a query param, look up and open the FAQ question
    if (!hashMatch) {
      return;
    }

    const hashSlug = window.location.hash.slice(5);

    if (hashSlug === slug) {
      setIsOpen(true);
      wrapperRef.current.open = 'true';
    }
  }, []);

  return (
    <Wrapper id={`faq-${slug}`} ref={wrapperRef}>
      <QuestionText
        style={{ color: isOpen ? 'hsl(45deg 100% 75%)' : undefined }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
      </QuestionText>
      <Answer>{answer}</Answer>
    </Wrapper>
  );
};

const dropIn = keyframes`
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0px);
  }
`;

const Wrapper = styled.details`
  scroll-margin-top: 150px;
`;

const QuestionText = styled.summary`
  cursor: pointer;
  font-size: 19px;
  font-weight: 400;
  outline-offset: 8px;
  margin-bottom: 16px;
  color: var(--color);

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

const Answer = styled.div`
  will-change: transform;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${dropIn} cubic-bezier(0.215, 0.61, 0.355, 1) 600ms;
  }
`;

export default Question;
